import React, { useState, useEffect } from 'react';
// import {fetchRandomDogPic} from './api/fetchDoggos';
// import {fetchCourseList} from '../netlify/functions/fetchUdemy';
import './App.css';
import InstallPWA from './InstallPWA';
import axios from 'axios';

const App = () => {    
    const [courses, setCourses] = useState([]);
    const [courseList, setCourseList] = useState();
    const [query, setQuery] = useState('');
    const [showIntro, setShowIntro] = useState(true);
    const toggleShowIntro = () => setShowIntro(!showIntro);
    
    const search = async (e) => {
      if(e.key === 'Enter') {              
        const data = await axios.get('/.netlify/functions/fetchUdemy', { params: { query } });
        console.log(data["data"]["msg"][0]["title"]);        
        setCourses(data["data"]["msg"]);              
        setQuery('');
        toggleShowIntro();
      }
  }

  useEffect(() => {    
    const courseTitles = courses.map((course) =>
        <>
          <div className="course-card">
            <img src={course.image_240x135} alt="more info"/>
            <p>{course.title}</p>
            <p>{course.price}</p>
          </div>
          
        </>
    );
    setCourseList(courseTitles);
  }, [courses])

    return (   
      <React.Fragment>            
          {showIntro && (
            <div> 
              <div>
                <img className="logo" src="./images/feedspinlogo800.png"  alt="Feedspin logo" />                        
              </div>
              <h3>Hello, what do you want to learn today?</h3>  
              <input type="text" className="search"placeholder="Search..."value={query}onChange={(e) => setQuery(e.target.value)}onKeyPress={search}/>               
              <InstallPWA /> 
            </div>
          )}                
              
          {courses && (                                                
              <div className="container">
                {courseList}           
              </div>                                                                    
          )}                
           
      </React.Fragment>
    )
}

export default App;